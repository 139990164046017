.js-Dropdown {
  display: inline-block;
  font: 400 14px sans-serif;
  position: relative;
  width: 20em;
}
.js-Dropdown-title {
  background: #ffffff;
  border: 1px groove #a5a5a5;
  box-sizing: border-box;
  cursor: pointer;
  font: 400 14px sans-serif;
  height: 3em;
  padding: 0.5em;
  position: relative;
  text-align: left;
  width: 100%;
}
.js-Dropdown-title:after {
  border-color: #a5a5a5 transparent transparent transparent;
  border-style: solid;
  border-width: 10px 12px;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  right: 1em;
  top: 45%;
  width: 0;
}
.js-Dropdown-list {
  background: #ffffff;
  border-left: 1px solid #a5a5a5;
  border-right: 1px solid #a5a5a5;
  box-sizing: border-box;
  display: none;
  height: 0;
  list-style: none;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  transition: 0.2s linear;
  width: 100%;
  z-index: 999;
}
.js-Dropdown-list.is-open {
  display: block;
  height: auto;
  opacity: 1;
}
.js-Dropdown-list li {
  border-bottom: 1px solid #a5a5a5;
  cursor: pointer;
  padding: 1em 0.5em;
}
.js-Dropdown-list li:hover {
  background-color: #fff5e9;
}
.js-Dropdown-list li.is-selected {
  background-color: #ffdfb6;
}
.js-Dropdown-optgroup {
  border-bottom: 1px solid #a5a5a5;
  color: #a5a5a5;
  cursor: default;
  padding: 1em 0.5em;
  text-align: center;
}